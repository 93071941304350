.header-information-lock {
  overflow: hidden !important;
  body {
    overflow: hidden !important;
  }
}

.header-information {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  z-index: 9999;
  font-size: 0;
  line-height: 0;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  overflow: hidden;
  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .header-information-content {
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .7);
    display: inline-block;
    width: 90%;
    max-width: 768px;
    vertical-align: middle;
    position: relative;
    padding: 40px 20px 0;
    box-sizing: border-box;
    max-height: 80%;
    max-height: 80vh;
    height: auto;
    overflow-x: hidden;
    font-size: medium;
    line-height: normal;

    .google-recaptcha > div {
      display: inline-block;
    }
    p {
      margin: 0 0 30px;
      color: #000;
      text-align: left;
      display: block;
      line-height: normal;
      font-size: medium;
    }
    &::after {
      display: block;
      content: '';
      clear: both;
      width: 100%;
      height: 1px;
    }
  }
  .header-information-content-btn {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .header-information-content-btn-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 37px;
    font-size: 20px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, .7);
    display: block;
    transition: color .2s linear;
    text-decoration: none;
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
  button {
    cursor: pointer;
    margin: 0 5px;
  }

  &__remove-account{
    text-align: left;
  }

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
  }

  .btn.btn-success {
    color: #fff;
    background-color: #A2C513;
    border-color: #8fae11;
    &:hover, &:focus {
      color: #fff;
      background-color: #7c960f;
      border-color: #2f3906;
    }
  }

  .btn.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;

    &:hover, &:focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19;
    }
  }
}

.header-information--top {
  position: relative;
  height: auto;
  background: transparent;
  .header-information-content {
    box-shadow: none;
    display: block;
    width: 100%;
    max-width: none;
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    height: auto;
    font-size: medium;
    line-height: normal;
    p {
      margin: 0;
    }
  }

}

